body {
  background: transparent;
}
.ant-table-thead > tr > th {
  background: transparent !important;
  color: #ffffff;
}
.ant-table-footer {
  background: transparent !important;
  color: #ffffff;
}
.ant-table {
  background: transparent !important;
  color: #ffffff;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgb(0 0 0 / 10%);
}
.ant-typography {
  color: #ffffff;
}
.ant-input-number-input {
  color: #ffffff;
}
.ant-switch {
  background-color: rgb(131 112 255 / 25%);
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
  border-right: none;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-table-thead > tr > th {
  border-bottom: none;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: none;
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: none;
}

.ant-table.ant-table-bordered > .ant-table-footer {
  border: none;
}

.ant-btn-primary {
  background: #6f70cc !important;
  border-color: #6f70cc !important;
}

.ant-input-number-handler {
  background: #6f70cc !important;
}

.ant-message-notice-content {
  background: #17171dad;
  border-radius: 50px;
}

.ant-message {
  color: #ffffff;
}

.spincenter {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

*{
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(59, 59, 59, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}